@media screen and (max-width:1920px){
  body {
    background-image: url("../public/img/tarkov.jpeg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
  }
}
@media screen and (min-width: 1921px) {
  body {
    background-image: url("../public/img/tarkov-4k.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
  }
}
@media screen and  (max-width:3840px) {
    .Header{
      display: flex;
      justify-content: center;
      align-items: center;
      grid-area: Header;
    }
    /* .Navbar{
      float: right;
      list-style:none;
      font-family: Tarkov;
      background-color: rgba(136, 130, 133, 0.7);
      width: fit-content;
      border-radius: 3rem; 
    } */
    .Flex-Container{
    display: flex;
    justify-content: center;
    align-items: center;
    }
    .Center{
      align-items: center;
      justify-content: center; 
    }
    .Block-Container{
      display: block;
    }
    .Navbar-Item{
      position: relative;
      z-index: 1;
      font-size: 1.1rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    .Navbar-Item.Mobile{
      display: block;
    }
    .Navbar{
      display: none;
      background: none;
    }
    .Navbar a{
      position: relative;
      display: inline-block;
      background: #1a1a1a;
      background-image: 
      linear-gradient(90deg, rgba(255, 255, 255, 0.1) 1px, transparent 1px),
      linear-gradient(rgba(255, 255, 255, 0.1) 1px, transparent 1px);
    background-size: 60px 60px; /* Size of each square */
    }
    .Navbar a:hover::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(185, 184, 184, 0.1); /* Adjust hover background color here */
      z-index: 0; /* Ensure it is behind the text */
    }
    .Navbar  button{
      text-decoration: none;
    }
    .Navbar.isOpen{
      display: flex;
      flex-direction: column;
    }
    .Inventory-Background{
      display: flex;
      flex-direction: column;
      list-style:none;
      font-family: Tarkov;
      float: none;
      width: fit-content;
      overflow: hidden;
    }
    
    .hvh{
    min-height: 100vh;
    }
    .footer-container{
      margin-top: auto;
      grid-area: Footer;
    }
    .Mobile-Player{
      display: none;
    }   
    .footer{
      display: flex;
      justify-content: center;
      align-items: center;
      color: green;
    }
    .Container{
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .nav-button {
      text-decoration: underline;
      margin: 1rem;
      background:none;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      cursor: pointer;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-family: Consolas, Courier New, monospace;
      border: none;
      font-size: 3rem;
      color: white;
      -webkit-transition: 500ms;
      transition: 500ms;
      border-radius: 5px;
    }
    .cardButton{
      margin-left: 1rem;
      margin-right: 1rem;
      margin-top: 1rem;
    }
    @font-face{
      font-family: Tarkov;
      src: url('./Fonts/Vtks\ Escape.ttf')
    }
    .tarkovFont{
      font-family: Tarkov;
    }
    #root{
      color: White;
      min-height: 100vh;
      width: 100vw;
      display: grid; 
      grid-auto-columns: auto; 
      grid-auto-rows: auto; 
      grid-template-columns: 0.38fr 1fr 1fr; 
      grid-template-rows: auto 1fr auto;; 
      gap: 0px 0px; 
      grid-template-areas: 
        "Header Header Header"
        "Navbar Content Content"
        "Footer Footer Footer"; 
    }
    .Login{
      display:flex;
      justify-content: center;
      align-items: center;
      margin-top: 10%;
      flex-direction: row;
      box-sizing: content-box;
    }
    .Login-Background{
      background-color:#1a1a1a; 
      color: white;
      border-radius: 0.5rem;
      width: 380px;
      height: 300px;
    }
    .Login-Form{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 380px;
      height: 300px;
    }
    .Profile-Background{
      position: static; 
      margin-top: 10%;
      background-color:#1a1a1a; 
      color: white;
      border-radius: 3rem;
      width: 380px;
      height: 300px;
    }
    .Profile-DetailsBg{
      display: block;
      background: #232323;
      width: 250px;
      border-radius: 4px;
    }
    .Profile-Input{
      background: none;
      border: none;
    }
    .Profile-Input-Container{
      display: block;
      background: #232323;
      width: 250px;
      border-radius: 4px;
    }
    .Profile-Details{
      padding-left: 1rem;
    }
    .Navbar-Container{
      display: flex;
      flex-direction: column;
      width: fit-content;
      grid-area: Navbar;
    }
    .Profile{
      display:flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-sizing: content-box;
      width: 380px;
      height: 300px;
    }
    .Profile-Button{
      color: black;
      background: green;
      border: none;
      border-radius: 4px;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    .Profile-Heading{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
    }
    .Content{
      grid-area: Content;
    }
    .Post{
      display:flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-sizing: content-box;
      width: 380px;
      height: 300px;
    }
    .Post-Background{
      position: static; 
      margin-top: 10%;
      background-color:#1a1a1a; 
      color: white;
      border-radius: 3rem;
      width: 380px;
      height: 300px;
    }
    .Post-Item{
      margin-top: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .Post-Button{
      color: black;
      background: green;
      border: none;
      border-radius: 4px;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      margin-top: 1rem;
    }
    .Register-Background{
      background-color:#1a1a1a; 
      color: white;
      border-radius: 0.5rem;
      width: 400px;
      height: 450px; 
    }
    .Register-Form{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 400px;
      height: 450px; 
    }
    .Form-Container{
      display: block;
      box-sizing: border-box;
      padding: 2rem;      
      margin: 1rem 0 1rem 0;
      background: #232323;
    }
    .Form-Container input{
      background: grey;
      color: black;
      border: none;
    }
    .Form-Container input::-webkit-input-placeholder{
      color: white;
    }
    .Login-Text{
      margin-right: 0.5rem;
    }
    .Form-Container-Button{
      color: black;
      background: green;
      border: none;
      border-radius: 4px;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
  
    }
    .Leaderboard-Container{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10%;
    }
    .Leaderboard-Background{
      background:#232323;
      width: fit-content;
      border-radius: 1rem;
    }
    .Leaderboard{
      text-align:left;
      border-collapse: collapse;
    }
    .Leaderboard-Head .Leaderboard-HeadItem{
      padding: 25px;
      color:green;
      font-size:14px;
      font-weight:bold;
    }
    .Leaderboard-Body .Leaderboard-BodyItem{
      padding:15px 25px;
      background:#1a1a1a;
      font-size:16px;
      border-bottom:5px solid #232323;
    }
    #Number0{
      color: purple;
    }
    .Leaderboard-BodyItem button{
    text-align: center;
    color: inherit;
    text-decoration: underline;
    background-color: inherit;
    border: none;
    border-color: none;
}
.Quest-Background{
  width: fit-content;
  border-radius: 4px;
  margin-top: 1rem;
}
.Quest-Tracker{
  text-align:left;
  border-collapse: collapse;
}
.Quest-TableHead .Quest-HeadItem{
  padding: 25px;
  color:green;
  font-size:14px;
  background: #232323;
  font-weight:bold;
}
.Quest-TableBody .Quest-BodyItem{
  padding:15px 25px;
  background:#1a1a1a;
  font-size:16px;
  border:5px solid #232323;
}
.Quest-BodyItem.Quest-Active{
  background: rgba(212, 104, 2 , 0.5);
}
.Quest-BodyItem.Quest-Completed{
  background: rgba(12, 93, 160, 0.5);
}
.Quest-Button{
  color: black;
  background: green;
  border: none;
  border-radius: 4px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 1rem;
}
}
@media screen and (max-width:820px) {
  #root{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .Navbar-Container{
    width: 100vW;
    display: block;
  }
}
@media screen and (max-width:480px) {
  #Quest-Trader{
    display: none;
  }
  #Quest-Wiki{
    display: none;
  }
}